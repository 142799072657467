
function handleWebviewBack(data) {
  sendToApp({ type: "handleWebviewBack", value: data });
}

function handleMobileDeviceBackButton(url) {
  window.handleBackKey = function () {
    console.log("BACK KEY PRESSED");
    window.location.href = url;
  };
}

function headerToggleAndroid(linkvalue) {
  sendToApp({
    type: "header_VISIBILITY",
    value: linkvalue,
  });
}

function headerToggleiOS(linkvalue) {
  sendToApp({
    type: "header_VISIBILITY",
    value: linkvalue,
  });
}

function superAdminRedirect(linkvalue) {
  sendToApp({
    type: "launchcustombrowser",
    value: linkvalue,
  });
}

function sendToApp(payload) {
  const encodedData = window.btoa(JSON.stringify(payload));
  if (window.android && window.android.__externalCall)
    window.android.__externalCall(encodedData);
  if (window.__externalCall) window.__externalCall(encodedData);
  if (
    isIOS() &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.callback
  )
    window.webkit.messageHandlers.callback.postMessage(encodedData);
}

function isIOS() {
  return /iPad|iPhone|iPod/.test(window.navigator.userAgent);
}

